import React from 'react';
import '../App.css';

const Services = props => {
return(
	<div>
        <section className="my-services" id="services">
            <h2 className="section__title section__title--services">What I do</h2>
            <div className="services">
                <div className="service">
                    <h3>Design + Development</h3>
                    <p>Solutioning and developing back-end services. Apply engineering principles and programming language to create software products. Lead teams and guide them through out the development life cycle.</p>
                </div> 
                
                <div className="service">
                    <h3>Employer &diams; Inchcape Shipping Services &diams; SEP 2020 - PRESENT</h3>
                    <p>Global trade has always depended on the port and since 1847 Inchcape Shipping Services has provided the local knowledge and means to deliver port calls that enable business to ship smoothly and trade successfully. Inchcape combines its worldwide infrastructure with local expertise through our global network of over 240 proprietary offices, across 60 countries and a team of more than 2,900 dedicated professionals. Our unique geographical footprint means we can offer customers a full range of services, delivered safely and with a consistent commitment to exceptional service, and transparency wherever they are in the world. Our diverse global customer base includes owners and charterers in the oil, cruise, container and bulk commodity sectors as well as naval, government and inter-governmental organisations. Through the real-time port data and information generated by our global network, coupled with investments in technology, Inchcape Shipping Services provides market and port intelligence that allows our customers to focus on their core business and make informed decisions.</p>
                    <h3>Previous Employer &diams; Wipro Limited &diams; SEP 2004 - AUG 2020</h3>
                    <p>Wipro Limited is a leading global information technology, consulting and business process services company. We harness the power of cognitive computing, hyper-automation, robotics, cloud, analytics and emerging technologies to help our clients adapt to the digital world and make them successful. A company recognized globally for its comprehensive portfolio of services, strong commitment to sustainability and good corporate citizenship, we have over 175,000 dedicated employees serving clients across six continents. Together, we discover ideas and connect the dots to build a better and a bold new future.</p>
                </div>
                
                <div className="service">
                    <h3>Certifications</h3>
                    <ul> 
						<li><a className="topnav" href="https://bit.ly/2TyXy6D)">AWS Certified Solution Architect Professional</a></li>
						<li><a className="topnav" href="https://bit.ly/36lmCTb">AWS Certified Solution Architect Associate</a></li>
						<li>Google Cloud Platform Fundamentals</li>
						<li>OutSystems Reactive Web Developer</li>
						<li>IBM Web Service Development</li>
						<li>Sun Certified Web Component</li>
						<li>Sun Certified Java Programmer</li>
					</ul>
                </div> 
            </div> 
            
            <a href="#work" className="btn">My Work</a>
        </section>
	</div>
	);
}
export default Services;
