import React from 'react';
import './App.css';
import MyHeader from './components/header'
import Home from './components/home'
import Services from './components/services'
import AboutMe from './components/about-me'
import MyWork from './components/my-work'
import ContactInfo from './components/contact-info'
import Footer from './components/footer'

function App() {
  return (
		<div>
            <MyHeader></MyHeader>
			<Home></Home>
			<Services></Services>
			<AboutMe></AboutMe>
			<MyWork></MyWork>
			<ContactInfo></ContactInfo>
			<Footer></Footer>
		</div>
  );
}

export default App;
