import React from 'react';
import nortel from '../../img/nortel-logo.jpg'

function NortelProjects(props) {
  if (!props.nrtl) {
    return null;
  }

  return (
    <div>
        <section className="my-projects" id="cbm">
            <div className="logo">
                <img src={nortel} alt="nortel logo"/>
            </div>
			<h2 className="section__title section__title--work">Nortel Networks &diams; Wipro Technologies</h2>		
			<h3 className="section__subtitle section__subtitle--project">Core Billing Manager</h3>
			<div className="about-me__body">
               <p>Module Lead &diams; Ottawa, Canada &diams; SEP'07 - APR'08</p>
			   <p>Core Billing Manager (CBM) is a dedicated, high performance computing platform that supports multiple OAM&P applications on the DMS product family and performs Element Management functions outlined in the TMN model.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Individual contributor and lead the team of product engineers to support the telecom product.</li>
						<li>The role included direct contact with end customer and network design team.</li>
						<li>Work closely with the software engineering and product management teams to design, deliver and manage our services with high uptime.</li>
						<li>Provide level-2 support for the client</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>UNIX, Shell scripting</p>
			</div>
		</section>

        <section className="my-projects" id="mg9k">
			<h3 className="section__subtitle section__subtitle--project">MG9KEM (Element Manager)</h3>
			<div className="about-me__body">
               <p>Senior Software Engineer &diams; Bangalore &diams; DEC'05 - AUG'07</p>
			   <p>This Java based EMS is built in a 3-tier Architecture (MVC) runs on Sun Solaris Netra240 Cluster system. The front end is coded in Java Swings and a third party ilog API for graphics. It uses RMI between client & mid-tier, CORBA between mid-tier and server. The EM and NE communicate each other through SNMP. The EM covers Fault, Configuration, Performance and Security Management. The EM uses Oracle Data Base to persist NE data.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Design, build, and maintain efficient, reusable, and reliable Java code.</li>
						<li>Implement performance and quality modules.</li>
						<li>Identify bottlenecks and bugs, and devise solutions to these problems.</li>
						<li>Help maintain code quality, organization, and automatization.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>Java 6, Swings, RMI, CORBA, PL/SQL, Oracle 10g Database, Weblogic.</p>
			</div>
            <a href="#work" className="btn">My Work</a>		
		</section>
    </div>
  );
}

export default NortelProjects;