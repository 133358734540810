import React from 'react';
import '../App.css';
import hemanshu from '../img/hemanshu-02.jpg'

const AboutMe = props => {
return(
	<div>
        <section className="about-me" id="about">
           <h2 className="section__title section__title--about">Who I am</h2>
           <p className="section__subtitle section__subtitle--about">Technical Solution Architect & developer based out of Chennai, India</p>
           
           <div className="about-me__body">
               <p>An experienced IT professional with a strong interest in projects that require both conceptual and analytical thinking. Fully committed to designing and developing innovative on-prem & cloud based materials that users will love. Always eager to learn more tricks from anyone regardless of the industry they're working in. My vision is reducing application delivery overhead and less effort in developing enterprise-scale applications to run on fully customized & automated infrastructure options with cognitive insight.</p>
           </div>
           
           <img src={hemanshu} alt="Hemanshu enjoying a drink" className="about-me__img"/>
        </section>
	</div>
	);
}
export default AboutMe;
