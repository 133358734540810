import React from 'react';
import '../App.css';
import hemanshu from '../img/hemanshu-01.jpg'

const Home = props => {
return(
	<div>
        <section className="intro" id="home">
            <h1 className="section__title section__title--intro">
                Hi, I am <strong>Hemanshu Patira</strong>
            </h1>
            <p className="section__subtitle section__subtitle--intro">Technical Solution Architect</p>
            <img src={hemanshu} alt="shiny weather" className="intro__img"/>
        </section>
	</div>
	);
}
export default Home;
