import React from 'react';
import scottishWater from '../../img/sw-logo.jpg'

function ScottishWaterProjects(props) {
  if (!props.sw) {
    return null;
  }

  return (
    <div>
        <section className="my-projects" id="sw">
            <div className="logo">
                <img src={scottishWater} alt=""/>
            </div>
			<h2 className="section__title section__title--work">Scottish Water &diams; Wipro Technologies</h2>		
			<h3 className="section__subtitle section__subtitle--project">Metering</h3>
			<div className="about-me__body">
               <p>Tecnhnical Lead &diams; Glasgow &diams; NOV'11 - JUL'12</p>
			   <p>Developed a workflow management for the Scottish Water staff to keep the track of the vacant properties meters and allow them to change the status and other flows related to metering management.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Responsible for producing technical design documentation and work flows using Oracle BPMN and BPEL processes.</li>
						<li>Responsible to producing service contract and integrating with handle held devices.</li>
						<li>Provide technical expertise and guidance to offshore team.</li>
						<li>Responsible for code quality and code reviews.</li>
						<li>Responsible for leadership role in managing and running the scrum for offshore team at Bangalore.</li>
						<li>Create frameworks and automation in the development process to maximize build efficiency and secure solid code.</li>
						<li>Develop and manage consistent and coherent DevOps processes and practices to support software development, testing, builds and deployment.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>Java/J2EE, Oracle 11g Database, Oracle Fusion Middleware - BPMN and BPEL, Oracle ADF, Weblogic.</p>
			</div>
            <a href="#work" className="btn">My Work</a>		
		</section>
    </div>
  );
}

export default ScottishWaterProjects;