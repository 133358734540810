import React from 'react';
import '../App.css';

const ContactInfo = props => {
return(
	<div>
        <section className="about-me" id="contact">
			
           <h2 className="section__title section__title--about">Contact</h2>
           <p className="section__subtitle section__subtitle--about">
			<b>Location:</b><br/>
			Chennai - 600 012<br/>
			India<br/>
			<b>Mobile:</b> +91-9345343268/+44-7466485576<br/>
			<b>Email:</b> <a href="mailto:hemanshupatira@gmail.com">hemanshupatira@gmail.com</a>
			</p>

        </section>
		<section className="my-projects">
            <a href="#top" className="btn">Home</a>		
		</section>	
	</div>
	);
}
export default ContactInfo;
