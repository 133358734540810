import React from 'react';
import '../App.css';

const Footer = props => {
return(
	<div>
        <footer className="footer">
            <a href="mailto:hemanshupatira@gmail.com" className="footer__link">hemanshupatira@gmail.com</a>
            <ul className="social-list">
                <li className="social-list__item">
                    <a className="social-list__link" href="https://www.linkedin.com/in/hemanshu-patira/">
                        <i className="fab fa-linkedin"></i>
                    </a>
                </li>
                <li className="social-list__item">
                    <a className="social-list__link" href="https://github.com/hemanshupatira">
                        <i className="fab fa-github"></i>
                    </a>
                </li>
            </ul>
        </footer>
	</div>
	);
}
export default Footer;
