import React from 'react';
import pearson from '../../img/pearson-logo.jpg'

function PearsonProjects(props) {
  if (!props.ps) {
    return null;
  }

  return (
    <div>
        <section className="my-projects" id="nbpts">
            <div className="logo">
                <img src={pearson} alt=""/>
            </div>
			<h2 className="section__title section__title--work">Pearson &diams; Wipro Technologies</h2>		
			<h3 className="section__subtitle section__subtitle--project">NBPTS</h3>
			<div className="about-me__body">
               <p>Tecnhnical Lead &diams; Chennai/Iowa, US &diams; MAY'08 - OCT'11</p>
			   <p>Developed a complete IT solution for the Pearson's Client - National Board of Professional Teaching Standards.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Responsible for producing technical design documentation for Fee Processing module.</li>
						<li>Responsible to producing service contract and integrating with payment gateways and refund cheques.</li>
						<li>Design finance related reports like Journal Entry Reports, Balance Sheet reports and Fee Operational reports.</li>
						<li>Responsible for code quality and code reviews.</li>
						<li>Responsible for leadership role in managing and running the scrum for offshore team at Bangalore.</li>
						<li>Provided guidance to the product users during client UAT phase at Iowa and San Antonio Texas.</li>
						<li>Develop and manage consistent and coherent DevOps processes and practices to support software development, testing, builds and deployment.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>Java/J2EE, Struts, JSP, EJB, JMS, PL/SQL, Oracle 10g Database, Jboss, Weblogic.</p>
			</div>
            <a href="#work" className="btn">My Work</a>		
		</section>
    </div>
  );
}

export default PearsonProjects;