import React from 'react';
import '../App.css';
import logo from '../img/hp.png';
import { menuData } from './menuData'
 
class MyHeader extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
		isSideBarOpen: false,
        covidStats: [],
        isCovidStatsLoaded: false
	};
  }
  handleMenuButtonClick = () => {
	this.setState(state => ({
      isSideBarOpen: !this.state.isSideBarOpen
    }));	
  };

  componentDidMount() {
        fetch('https://7til0kw0z4.execute-api.ap-south-1.amazonaws.com/prod/CovidGlobalStats')
            .then(res => res.json())
            .then((data) => {
                this.setState({ covidStats: data })
				this.setState({isCovidStatsLoaded: true})
				console.log(this.state.covidStats)
            })
            .catch(console.log)
    }

  render() {
  const { isSideBarOpen } = this.state;
  
return(
	<div>
        <header>
		
            <div className="logo" id="top">
                <img src={logo} alt="hemanshu patira logo"/>
            </div>
			<div>
								<marquee width='1000px'>COVID-19 Total Confirmed Cases: {this.state.covidStats.totalConfirmedCases}; Total Recovered Cases: {this.state.covidStats.totalRecoveredCases}; Total Death Cases: {this.state.covidStats.totalDeathCases}</marquee>
			</div>
			<div>
			<div className="menu-button" onClick={this.handleMenuButtonClick}>
				<i className="fas fa-bars"/>
			</div>
			
			{/* Side bar */}
				{ menuData.length && (
					<nav className={`nav ${isSideBarOpen ? 'show' : ''}`}>
						<div className="close" onClick={this.handleMenuButtonClick}>
						<i className="fas fa-times"/>
						</div>
						<ul className="menu-items">
							{menuData.map(item => (
								<li className="menu-list" key={item.label}>
									<a href={item.url}>{item.label}</a>
								</li>
							))}
						</ul>
					</nav>
				)}
			</div>	

        </header>
     </div>	
	 );
	 }
}
export default MyHeader;
