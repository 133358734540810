import React from 'react';
import capitalOne from '../../img/capitalone-logo.jpg'

function CapitalOneProjects(props) {
  if (!props.capone) {
    return null;
  }

  return (
    <div>
		<section className="my-projects" id="psd2">
            <div className="logo">
                <img src={capitalOne} alt=""/>
            </div>
			<h2 className="section__title section__title--work">Capital One &diams; Wipro Technologies</h2>		
			<h3 className="section__subtitle section__subtitle--project">PSD2/Open Banking</h3>
			<div className="about-me__body">
               <p>Tecnhnical Lead &diams; Nottingham &diams; APR'17 - AUG'20</p>
			   <p>Developed Open Banking solution for Capital One Cards to meet Payment Service Directive 2 compliance.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Estimate the project/solution development effort and resource requirements.</li>
						<li>Works on unusually complex problems and provides solutions, which are highly innovative.</li>
						<li>Individual contributor in developing and testing RESTFul APIs.</li>
						<li>Responsible for leadership role in managing and running the scrum for offshore team at Bangalore.</li>
						<li>Contributes technical expertise to help determine requirements and functional specifications for entire system.</li>
						<li>Interact with various stake holders and product owners for refining epics & stories for the agile sprints.</li>
						<li>RFI's (Request for Information), RFP's (Request for Proposal), SOW's (Statement of Work), SOC's (Statement of Compliance) & ITT's (Invitation to Tender); Documents such as Functional Requirement, Technical Design, Work Plan, Configuration, Installation, Deployment and User Manuals.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>Java 8, Spring Framework, Spring Boot, Spring Controllers, Spring-AOP, Spring Security, OAuth2, OpenId, Hibernate, Postgres, H2, Python, Tyk, Git, SonarQube, Artifactory, App Dynamics, DataDog, AWS Cloud � EC2, ELB, R53, Lambda, IAM, SG, S3, RDS, Elasticache</p>
			</div>
		</section>
		<section className="my-projects" id="creditwise">
			<h3 className="section__subtitle section__subtitle--project">CreditWise</h3>
			<div className="about-me__body">
               <p>Tecnhnical Lead &diams; Nottingham &diams; JAN'16 - MAR'17</p>
			   <p>Capital One provides free credit score from Equifax bureau and credit coaching through CreditWise application.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Design the backend APIs needed for registration and to fetch and display the bureau data from Equifax.</li>
						<li>Design and implemented crucial APIs like identity verification and device verification for CreditWise registration process.</li>
						<li>Responsible for presenting the technical design documentation for integrating with the bureau APIs.</li>
						<li>Interact with various stake holders and product owners for refining epics & stories for the agile sprints.</li>
						<li>Create frameworks and automation in the development process to maximize build efficiency and secure solid code.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>Java 7, Jersey, Spring Framework, Hibernate, Oracle 11g Database, Oracle Fusion Middleware - BPEL, IBM Datapower, AKANA, GitLab, App Dynamics.</p>
			</div>				
		</section>	
        
		<section className="my-projects" id="ccard">
			<h3 className="section__subtitle section__subtitle--project">Credit Card Acquisition System</h3>
			<div className="about-me__body">
               <p>Tecnhnical Lead &diams; Nottingham &diams; AUG'12 - DEC'15</p>
			   <p>Credit Card Acquisition System managed the work flow of a credit card application, right from the data entered by the customer on the Capital One site till the account created in the card platform.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Responsible for producing technical design documentation and work flows using Oracle BPMN and BPEL processes.</li>
						<li>Responsible to producing service contract and integrating with various vendors like Credit Bureaus and Fraud Agencies.</li>
						<li>An individual contributor on complex components like card application decisioning service and integration with external vendors.</li>
						<li>Create frameworks and automation in the development process to maximize build efficiency and secure solid code.</li>
						<li>Develop a Community of Practice across the development team to drive practice adoption.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>Java/J2EE, JSP, Spring Framework, Hibernate, Oracle 11g Database, Oracle Fusion Middleware - BPMN and BPEL, Weblogic, IBM Datapower, AKANA, SVN, SolarWinds.</p>
			</div>				
            <a href="#work" className="btn">My Work</a>		
		</section>	
    </div>
  );
}

export default CapitalOneProjects;
