export const menuData = [
	{
		label: 'Home',
		url: '#home'
	},
	{
		label: 'My Services',
		url: '#services'
	},
	{
		label: 'About me',
		url: '#about'
	},
	{
		label: 'My Work',
		url: '#work'
	},
	{
		label: 'Resume',
		url: 'https://hemanshu-patira-portfolio-app.s3.ap-south-1.amazonaws.com/Hemanshu_Patira.pdf'
	},
	{
		label: 'Contact',
		url: '#contact'
	}
]