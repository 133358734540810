import React from 'react';
import issLogo from '../../img/iss-logo.jpg'

function InchcapeProjects(props) {
  if (!props.iss) {
    return null;
  }

  return (
    <div>
        <section className="my-projects" id="iss">
            <div className="logo">
                <img src={issLogo} alt=""/>
            </div>
			<h2 className="section__title section__title--work">Inchcape Shipping Services</h2>		
			<h3 className="section__subtitle section__subtitle--project">ISS IT Architecture</h3>
			<div className="about-me__body">
               <p>Tecnhnical Solution Architect &diams; Chennai &diams; SEP'20 - PRESENT</p>
			   <p>Part of ISS IT Architecture team as a Technical Solution Architect. The IT Architecture team is responsible for supporting the planning, designing and development of the new and existing core products to support various lines of business at ISS.</p>
	        </div>		   
			<div className="my-responsibilities">
				<h4>Responsibilities:</h4>
                    <ul> 
						<li>Provide technical designs and solutions</li>
						<li>Collaboratively maintain & propose changes to the design / architectural / technology standards, principles, framework and governance around it.</li>
						<li>Ensuring architectural standards, governance, principles are implemented effectively by auditing assigned IT programs, projects, products and small works.</li>
						<li>Optimisation of IT deliverables for business value realization with reusability of solutions, architectural building blocks & components, designs and standardization.</li>
						<li>Review new designs against architectural standards and business requirements.</li>
						<li>Ensure Business Impact Assessment / Business Continuity & DR processes, standards and resilience is tested and meet the business requirements as agreed with business.</li>
						<li>Assess new technology for adoption and implementation within Inchcape.</li>
						<li>Lifecycle management from emerging technology from Innovation department through to end of life.</li>
					</ul>
			</div>
			<div className="my-responsibilities">
				<h4>Technologies:</h4>
                    <p>AWS Cloud – EC2, ECS, ELB, R53, Lambda, IAM, SG, S3, RDS, Elasticache, etc; OutSystems, Dell Boomi, Unit4 Financial.</p>
			</div>
            <a href="#work" className="btn">My Work</a>		
		</section>
    </div>
  );
}

export default InchcapeProjects;