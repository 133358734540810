import React from 'react';
import '../App.css';
import iss from '../img/iss-logo.jpg'
import capitalOne from '../img/capitalone-logo.jpg'
import scottishWater from '../img/sw-logo.jpg'
import pearson from '../img/pearson-logo.jpg'
import nortel from '../img/nortel-logo.jpg'
import InchcapeProjects from './mywork/inchcape'
import CapitalOneProjects from './mywork/capitalone'
import ScottishWaterProjects from './mywork/scottish-water'
import PearsonProjects from './mywork/pearson'
import NortelProjects from './mywork/nortel'



class MyWork extends React.Component {
constructor(props) {
    super(props);
	this.state = {showInchcape: false,
	showCapitalOne: false,
	showScottishWater: false,
	showPearson: false,
	showNortel: false};
	this.handleInchcapeProjects = this.handleInchcapeProjects.bind(this);
	this.handleCapitalOneProjects = this.handleCapitalOneProjects.bind(this);
	this.handleScottishWaterProjects = this.handleScottishWaterProjects.bind(this);
	this.handlePearsonProjects = this.handlePearsonProjects.bind(this);
	this.handleNortelProjects = this.handleNortelProjects.bind(this);

  }
  handleInchcapeProjects() {
  this.setState(state => ({
      showInchcape: !state.showInchcape,
	  showCapitalOne: false,
	  showScottishWater: false,
	  showPearson: false,
	  showNortel: false
    }));
  }
  handleCapitalOneProjects() {
  this.setState(state => ({
      showCapitalOne: !state.showCapitalOne,
      showInchcape: false,
	  showScottishWater: false,
	  showPearson: false,
	  showNortel: false

    }));
  }
  handleScottishWaterProjects() {
  this.setState(state => ({
      showScottishWater: !state.showScottishWater,
      showInchcape: false,
	  showCapitalOne: false,
	  showPearson: false,
	  showNortel: false
    }));
  }
  handlePearsonProjects() {
  this.setState(state => ({
      showPearson: !state.showPearson,
      showInchcape: false,
	  showScottishWater: false,
	  showCapitalOne: false,
	  showNortel: false
    }));
  }
  handleNortelProjects() {
  this.setState(state => ({
      showNortel: !state.showNortel,
      showInchcape: false,
	  showScottishWater: false,
	  showPearson: false,
	  showCapitalOne: false	  
    }));
  }
  render() {
return(
	<div>
	<div>
        <section className="my-work" id="work">
            <h2 className="section__title section__title--work">My work</h2>
            <p className="section__subtitle section__subtitle--work">A selection of my range of work</p>
            
            <div className="portfolio">
                <a href="#iss" onClick={this.handleInchcapeProjects} className="portfolio__item">
                    <img src={iss} alt="" className="portfolio__img"/>
                </a>
                
                <a href="#psd2" onClick={this.handleCapitalOneProjects} className="portfolio__item">
                    <img src={capitalOne} alt="" className="portfolio__img"/>
                </a>
                
                <a href="#sw" onClick={this.handleScottishWaterProjects} className="portfolio__item">
                    <img src={scottishWater} alt="" className="portfolio__img"/>
                </a>
                
                <a href="#nbpts" onClick={this.handlePearsonProjects} className="portfolio__item">
                    <img src={pearson} alt="" className="portfolio__img"/>
                </a>
                
                <a href="#cbm" onClick={this.handleNortelProjects} className="portfolio__item">
                    <img src={nortel} alt="" className="portfolio__img"/>
                </a>                
            </div>
        </section>
	</div>
	<div >
	<InchcapeProjects iss={this.state.showInchcape} />
	<CapitalOneProjects capone={this.state.showCapitalOne} />
	<ScottishWaterProjects sw={this.state.showScottishWater} />
	<PearsonProjects ps={this.state.showPearson} />
	<NortelProjects nrtl={this.state.showNortel} />

	</div>
	</div>
	);
	}
}
export default MyWork;
